import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { SharedDataService } from './shared-data.service';
import { ToastrService } from 'ngx-toastr';
import {ROLE} from '../../@Modules/@website/@auth/auth-material/auth-modal/permission-role';
const baseUrl = environment.baseUrl;

const apiRoutes = {
  verifyUser: () => `${baseUrl}/accounts/verify-guest-user/`,
  loginWithOTP: () => `${baseUrl}/accounts/login-guest-user/`,
  userRegistration: () => `${baseUrl}/client-admin/user-registration/`,
  verifyGuestUser: () => `${baseUrl}/accounts/guest-user-verify/`,
  loginGuestUser_OTP: () => `${baseUrl}/accounts/login-guest-user/`,
  getNewAccess_token: () => `${baseUrl}/accounts/api/token/refresh/`,
  getOtp: () => `${baseUrl}/accounts/forget-password/`,
  verifyOtp: () => `${baseUrl}/accounts/verify-otp-forget-password/`,
  savePwd: () => `${baseUrl}/accounts/update-user-password/`,
  resendOtp: () => `${baseUrl}/accounts/resend-otp/`,
  logoutAndBlockToken: () => `${baseUrl}/accounts/logout/`,
  
};


@Injectable({
  providedIn: 'root'
})
export class AuthClientService {
  userLoginData:any = new BehaviorSubject(null);
  constructor(private httpClient: HttpClient,
    private router: Router,
    private sharedDataService: SharedDataService,
    private toaster:ToastrService
  ) { }

  postVerifyGuestUser(body: any) {
    return this.httpClient.post<any>(apiRoutes.verifyGuestUser(), body);
  }

  loginGuestUser_OTP(body: any) {
    return this.httpClient
      .post<any>(apiRoutes.loginGuestUser_OTP(), body)
      .pipe(
        tap((res) => {
          console.log('res', res)
          const clientUserData = {
            email: res.user_email,
            userID: res.user_id,
            userType: res.user_type,
            _token: res.token.access,
            _retoken: res.token.refresh,
            kyc_status: res.kyc_status,
            kyc_approved: res.kyc_approved,
            plan_expired: res.plan_expired,
            address: res.address,
            organization_id: res.organization_id,
            organization_name: res.organization_name,
            payment_status: res.payment_status,
            pending_plan: res.pending_plan,
            user_name: res.user_name,
            full_address:res.full_address,
            user_role:res.user_role,
            role_head:res.role_head,
          };
          this.clientAuthentication(clientUserData);
          console.log(res, ' __Login User__');
        })
      );
  };
  resendOtp(data:any){
    return this.httpClient.post<any>(apiRoutes.resendOtp(),data);
  };
  logoutAndBlockToken(){
    const {_retoken} = this.getUserProfile();
    return this.httpClient.post<any>(apiRoutes.logoutAndBlockToken(),{refresh:_retoken});
  };
  
  setNewAccess_token(refresh_token:string){
    const body = {refresh:refresh_token};
    this.httpClient.post<any>(apiRoutes.getNewAccess_token(),body).subscribe(
      (res)=>{
        const loginData: any = localStorage.getItem('websiteUser');
        const websiteUser = JSON.parse(loginData);
        let with_updateAccess_token = JSON.stringify({... websiteUser, _token:res.access});
        localStorage.setItem('websiteUser', with_updateAccess_token);
        this.toaster.warning('Please Refresh the page');
      },(err)=>{
        console.log(err);
        this.toaster.error('please login again','Session Expired');
        this.logout();
      }
    )
  }

  clientAuthentication(data: any) {
    let loginClientUser = JSON.stringify(data);
    localStorage.setItem('websiteUser', loginClientUser);
    this.sharedDataService.sharedData({ 'login': true });
  }

  logout() {
    this.logoutAndBlockToken().subscribe((res)=>this.cleanUserSession(),(err)=>this.cleanUserSession());
  };

  cleanUserSession(){
    localStorage.removeItem('websiteUser');
    localStorage.removeItem('menusState');
    this.router.navigate(['/website/']);
    this.sharedDataService.sharedData({ 'login': false });
  };

  user_profile() {
   this.router.navigate(['/website/user-profile']);
  }

  setLoginUserData(data:any){
    this.userLoginData.next(data);
  }

  getLoginUserData(){
    return this.userLoginData;
  }

  getUserProfile() {
    const userProfile: any = localStorage.getItem('websiteUser');
    return JSON.parse(userProfile);
  }

  updateUserProfileLocally(newProfileData: any) {
    let loginClientUser = JSON.stringify(newProfileData);
    localStorage.setItem('websiteUser', loginClientUser)
  }

  postUserRegistration(data: any): Observable<any> {
    return this.httpClient.post<any>(apiRoutes.userRegistration(), data);
  }

  verifyUser(body: any) {
    return this.httpClient.post<any>(apiRoutes.verifyUser(), body);
  }

  getOtp(body: any) {
    console.log('get otp', body)
    return this.httpClient.post<any>(apiRoutes.getOtp(), body);
  }
  verifyOtp(body: any) {
   return this.httpClient.post<any>(apiRoutes.verifyOtp(), body);
  }
  savePwd(body: any) {
    return this.httpClient.post<any>(apiRoutes.savePwd(), body);
   }


  checkPermission(component:any){
    let loginData: any = localStorage.getItem('websiteUser');
    const loginUser = JSON.parse(loginData);
    const loginUser_Role = loginUser.user_role;
    const all = component.permission.includes(ROLE.All);
    const isAdmin = loginUser.user_role.map((role: any) => role.toLowerCase()).includes('admin');

    const access_role = [];
    loginUser_Role.forEach((a:any)=>{
        for(let pr_role of component.permission){
            if(a.toLowerCase() == pr_role.toLowerCase()){
              access_role.push(pr_role);
            };
        }
    });

    if((loginUser.kyc_status == "submitted" || loginUser.kyc_status == "approved") && loginUser.payment_status == "activated" && !loginUser.plan_expired && !loginUser.pending_plan){
      if(component.page == 'kyc' || component.page == 'package' || component.page == 'termandcondition'){
        return false;
      }else{
        if(all){
          return true;
        }else{
          if(access_role.length !== 0){
            return true;
          }else{
            return false;
          };
        };
      }
    }else{
        if (component.page == 'create'){
          return true;
        }else if (component.page == 'kyc'){
          if(loginUser.kyc_status == "pending" && loginUser.payment_status == "Not Done" && loginUser.plan_expired){
            return true
          }else{
            return false;
          };
        }else if(component.page == 'package' || component.page == 'termandcondition'){
          if(loginUser.kyc_status == "submitted" && (loginUser.payment_status == "Not Done" || loginUser.payment_status == "pending" || loginUser.payment_status == 'expired') && (loginUser.plan_expired || loginUser.pending_plan)){
            return true
          }else{
            return false;
          };
        }else if(component.page == 'dashboard'){
          if(isAdmin){
            return true;
          }else{
            return false;
          };
        }else if (component.page == 'compare'){
          if(isAdmin){
            return true;
          }else{
            return false;
          };
        }else if (component.page == 'subscriber-landing'){
          if(isAdmin){
            return true;
          }else{
            return false;
          };
        }else if (component.page == 'assessment-selection'){
          if(isAdmin){
            return true;
          }else{
            return false;
          };
        }else if (component.page == 'user-profile'){
          if(isAdmin){
            return true;
          }else{
            return false;
          };
        }else{
          return false;
        };
    };
  };

  setCookieForRemember(data:any){
    const cr = window.btoa(JSON.stringify(data));
    localStorage.setItem('ikoo_rmbr',cr);
  }
}
